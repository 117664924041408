<template>
  <div>
    <div style="margin: 20px 0 0 20px"><MyBreadcrumb></MyBreadcrumb></div>
    <div class="analysis">
      <div class="item" v-for="(item,index) in dataList" :key="index">
        <p>{{item.name}}:</p>
        <p>{{item.value}}</p>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "analysis.png",
  data(){
    return {
      dataList:[],
    }
  },
  created(){
    this.getData();
  },
  methods:{
    getData(){
      this.api.system.systemMqStat().then((res)=>{
        this.dataList = [];
        for(let name in res.stats){
          this.dataList.push({
            name:name,
            value:res.stats[name]
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.analysis{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  //display: flex;
  //justify-content: flex-start;
  //flex-wrap: wrap;
  font-size: 14px;

  .item{
    margin-right: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;

    >p:nth-child(2){
      font-weight: bold;
      margin-left: 5px;
    }
  }
}
</style>
